import merge from "deepmerge"
import defaultThemeColors from "gatsby-theme-blog/src/gatsby-plugin-theme-ui/colors"

/*
 * Want to change your theme colors?
 * Try uncommenting the color overrides below
 * to go from default purple to a blue theme
 */

// const darkBlue = `#007acc`
// const lightBlue = `#66E0FF`
// const blueGray = `#282c35`
const offWhite = '#fffff2';
const charcoal = '#0d0d0d';
const cyan = '#04ADBE';
const pink = '#FF3686';

export default merge(defaultThemeColors, {
  text: charcoal,
  background: offWhite,
  accent: pink,
  modes: {
    dark: {
      text: offWhite,
      background: charcoal,
      inversePrimary: offWhite,
      highlight: cyan,
      accent: cyan,
      primary: cyan,
    },
    light: {
      text: charcoal,
      background: offWhite,
      inversePrimary: charcoal,
      highlight: pink,
      accent: pink,
      primary: pink,
    },
  },
})
